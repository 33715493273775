import React from "react";
import { Link, graphql } from "gatsby";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import { useI18next } from "gatsby-plugin-react-i18next";

const NotFoundPage = () => {
  const { language, t } = useI18next();
  return (
    <Layout>
      <SEO title={t("ไม่พบหน้าที่คุณต้องการ")} />
      <div className="container mt-10">
        <div className="row justify-content-center">
          <div className="col-auto">
            <h1>{t("ไม่พบหน้าที่คุณต้องการ")}</h1>
          </div>
        </div>
      </div>
      <div className="row justify-content-center mt-8">
        <div className="col-auto">
          <Link className="button button-primary" to="/">
            {t("กลับหน้าหลัก")}
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default NotFoundPage;
